        <template>
  <!-- Container Card -->
  <b-card>
    <b-row>
        </b-row>
        <b-tabs
      ref="my-tabs"
      v-model="currentTab"
      pills
      nav-class="scrollable-tabs-nav"
    >
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-sm-inline">Дерево</span>
          </template>
          <div>
            <b-row
                          >
                                                                    <b-col
                                                                                                                                      sm="12"
                                                                  >
                        <div
                                                    class="row align-items-end"
                                                  >
                                                      <div
                                                              class="col-12 col-sm-auto"
                                                            >
                                                                <sp-select
  id="xparam1"
  name="xparam1"
    :value.sync="xparam1"
    label="Масштаб"
  />                            </div>
                                                      <div
                                                              class="col-12 col-sm-auto mb-1"
                                                            >
                                                                <home-button
  label="На верх"
    variant="outline-primary"
        classes="mr-50"
        />                            </div>
                                                      <div
                                                              class="col-12 col-sm-auto mb-1"
                                                            >
                                                                <forward-button
  label="Назад"
    variant="outline-primary"
        classes="mr-50"
      />                            </div>
                                                      <div
                                                              class="col-12 col-md-6"
                                                            >
                                                                <search-input
    :value.sync="qqq"
    api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/search/list/struct"
    />
                            </div>
                                                  </div>
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <tree-structure
    :xparam1="xparam1"
      :apiId="qqq"
        :loadByTab="0"
      :currentTab="currentTab"
      :autoload="true"
        api="https://api.vestaorganic.ru/api/v1/htm/lk/buyer/binarthree/page"
/>                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-sm-inline">По уровням</span>
          </template>
          <div>
            <b-row
                          >
                                                                    <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <html-content
  api="https://api.vestaorganic.ru/api/v1/htm/lk/service/data/page/binarLev"
      :loadByTab="1"
      :currentTab="currentTab"
      :autoload="false"
    />
                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-sm-inline">Со сжатием</span>
          </template>
          <div>
            <b-row
                          >
                                                                    <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <html-content
  api="https://api.vestaorganic.ru/api/v1/htm/lk/service/data/page/binarCompress"
      :loadByTab="2"
      :currentTab="currentTab"
      :autoload="false"
    />
                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
          </b-tabs>
      </b-card>
</template>

<script>
/* eslint-disable */
// prettier-ignore
import {
  BTab, BTabs, BCard, BRow,
  BCol, VBModal,
} from 'bootstrap-vue'

import { ref, watch } from '@vue/composition-api'


                                                                                                    import SpSelect from '@core/components/input/select/SpSelect.vue'
                                                                                                                        import HomeButton from '@core/components/button/HomeButton.vue'
                                                                                                                        import ForwardButton from '@core/components/button/ForwardButton.vue'
                                                                                                                        import SearchInput from '@core/components/input/search-input/SearchInput.vue'
                                                                                                                                            import TreeStructure from '@core/components/tree-structure/TreeStructure.vue'
                                                                                                                                            import HtmlContent from '@core/components/html-content/HtmlContent.vue'
                                                                                                                                      
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
                                                                                                                    SpSelect,
                                                                                                    HomeButton,
                                                                                                    ForwardButton,
                                                                                                    SearchInput,
                                                                                                                                TreeStructure,
                                                                                                                                              HtmlContent,
                                                                                                                                                        },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isMouseDown: false,
      startX: null,
      scrollLeft: null,
      navElement: null,
    }
  },
  mounted() {
    this.navElement = this.$refs['my-tabs'].$el.querySelector('ul')

    this.navElement.addEventListener('mousedown', this.onMouseDown)
    this.navElement.addEventListener('mouseup', this.onMouseUp)
    this.navElement.addEventListener('mousemove', this.onMouseMove)
    this.navElement.addEventListener('mouseleave', this.onMouseLeave)

    this.navElement.addEventListener('touchstart', this.onTouchStart)
    this.navElement.addEventListener('touchmove', this.onTouchMove)
  },
  onBeforeDestroy() {
    this.navElement.removeEventListener('mousedown', this.onMouseDown)
    this.navElement.removeEventListener('mouseup', this.onMouseUp)
    this.navElement.removeEventListener('mousemove', this.onMouseMove)
    this.navElement.removeEventListener('mouseleave', this.onMouseLeave)

    this.navElement.removeEventListener('touchstart', this.onMouseDown)
    this.navElement.removeEventListener('touchmove', this.onMouseMove)
  },
  methods: {
    // Scrolling tabs methods
    onTouchStart(event) {
      event.stopPropagation()
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onTouchMove(event) {
      event.stopPropagation()
      event.preventDefault()
      const x = event.touches[0].pageX - this.navElement.offsetLeft
      const speed = 1 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
    onMouseDown(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = true

      this.startX = event.pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onMouseUp(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseLeave(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseMove(event) {
      if (!this.isMouseDown) return

      event.stopPropagation()
      event.preventDefault()
      const x = event.pageX - this.navElement.offsetLeft
      const speed = 1.5 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
  },
  setup() {
                const qqq = ref('')
            const xparam1 = ref('100%')
            const currentTab = ref()
          
    return {
                          qqq,
                  xparam1,
                  currentTab,
                  }
  },
}
</script>
<style lang="scss">
.scrollable-tabs-nav {
  flex-wrap: nowrap;
  overflow: hidden;
  padding-bottom: 1rem;
}
</style>
    