<template>
  <b-button
    :variant="variant"
    :size="size"
    :class="classes"
    @click="handleClick"
  >
    <feather-icon
      icon="HomeIcon"
      class="mr-50"
    />
    <span class="align-middle">{{ label }}</span>
  </b-button>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const { router } = useRouter()
    const handleClick = () => {
      router.push({ name: 'tree-structure' })
    }

    return {
      handleClick,
    }
  },
}
</script>
