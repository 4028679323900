<template>
  <b-form-group class="w-100">
    <label
      for="search"
    >Поиск</label>
    <b-input-group>
      <b-input-group-prepend
        is-text
        class="nav-btn"
      >
        <b-navbar-nav class="nav">
          <b-nav-item-dropdown
            link-classes="history-star"
            lazy
          >
            <div
              slot="button-content"
            >
              <feather-icon
                icon="ListIcon"
                size="18"
                class="text-primary"
              />
              <span class="ml-50">История</span>
            </div>

            <li style="min-width:300px">
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="search-list search-list-history scroll-area"
                :class="{'show': history && history.length }"
                tagname="ul"
              >

                <b-dropdown-item
                  v-for="(suggestion, index) in history"
                  :key="index"
                  class="suggestion-group-suggestion cursor-pointer"
                  link-class="d-flex align-items-center"
                  @mouseenter="currentSelected = index"
                  @click="() => {
                    $emit('update:value', suggestion)
                  }"
                >
                  <div class="d-flex justify-content-between w-100">
                    <span class="align-middle">{{ suggestion.FIO }}</span>
                    <feather-icon
                      icon="DeleteIcon"
                      class="ml-1 text-danger"
                      size="16"
                      @click.stop.prevent="removeFromHistory(suggestion)"
                    />
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-show="!history || !history.length"
                  disabled
                >
                  Нет данных
                </b-dropdown-item></vue-perfect-scrollbar>
            </li>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-input-group-prepend>
      <v-select
        id="search"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        :value="value"
        :clearable="true"
        :filterable="false"
        :get-option-label="option => option.FIO"
        class="search-input form-control p-0"
        @input="searchInput"
        @search="fetchData"
      >
        <template v-slot:no-options="{ search, searching }">
          <template
            v-if="searching"
          >
            По запросу <em>{{ search }}</em> ничего не найдено.
          </template>
          <em
            v-else
            style="opacity: 0.5;"
          >Поиск...</em>
        </template>
      </v-select>
      <b-input-group-append is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem,
  BInputGroupAppend, BInputGroupPrepend, BInputGroup,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, onUnmounted } from '@vue/composition-api'
import searchInputStoreModule from './searchInputStoreModule'
import useSearchInput from './useSearchInput'
import store from '@/store'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    VuePerfectScrollbar,
    BDropdownItem,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, null],
      default: null,
    },
    api: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'search-input'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, searchInputStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      options,
      fetchData,
    } = useSearchInput(props)

    if (!localStorage.getItem('search-history-data')) {
      localStorage.setItem('search-history-data', JSON.stringify({ items: { data: [], key: 'search' } }))
    }

    const searchHistoryData = JSON.parse(localStorage.getItem('search-history-data'))
    const searchHistoryDataItems = ref(searchHistoryData.items)
    const history = ref(searchHistoryData.items.data)
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const removeFromHistory = item => {
      const itemIndexInHistory = history.value.findIndex(i => i.id === item.id)
      history.value.splice(itemIndexInHistory, 1)
      localStorage.setItem('search-history-data', JSON.stringify({ items: searchHistoryDataItems.value }))
    }

    const searchInput = val => {
      if (val) {
        const itemIndexInHistory = history.value.findIndex(i => i.id === val.id)
        if (itemIndexInHistory < 0) {
          history.value.push(val)
          localStorage.setItem('search-history-data', JSON.stringify({ items: searchHistoryDataItems.value }))
        }
      }
      emit('update:value', val)
    }

    return {
      history,
      perfectScrollbarSettings,
      currentSelected,
      removeFromHistory,

      options,
      searchInput,
      fetchData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
<style scoped>
  .nav >>> .dropdown-toggle:after {
    display: none!important;
  }
  .nav-btn >>> .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
  }
  .search-input {
    height: auto;
  }
  .search-input >>> .vs__dropdown-toggle {
    border-radius: 0;
    border: 0;
    width: 100%;
  }
</style>
