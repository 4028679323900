import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'

export default function useSelectInput(props) {
  const value = ref(null)
  const options = ref([])

  const fetchData = debounce(val => {
    if (val && val.length > 2) {
      store.dispatch('search-input/fetchData', {
        api: props.api,
        params: {
          q: val,
        },
      })
        .then(response => {
          const { data } = response.data
          options.value = data
        })
        .catch(() => {
          options.value = []
        })
    }
  }, 500)

  return {
    value,
    options,
    fetchData,
  }
}
