<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :class="classess"
  >
    <v-select
      :id="id"
      :options="['100%', '70%', '50%', '30%']"
      :value="value"
      :clearable="false"
      :searchable="false"
      class="w-100"
      @input="(val) => {
        $emit('update:value', val)
      }"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: [Object, String, null],
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    classess: {
      type: String,
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.w-100 .vs__dropdown-toggle {
  width: 100px;
}
</style>
